import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./screens/webinar-replay/webinar-video"
import BodyLanding from "./screens/webinar-replay/2023-03-08-fish/body-landing"

const DoximityDocNewsFish = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Updates on Influenza and COVID-19: Trends and Treatments for the Urgent Care Setting"
				videoDisplayTitle="Updates on Influenza and COVID-19: Trends and Treatments for the Urgent Care Setting"
				vimeoLink="https://player.vimeo.com/video/811407415?h=40949d0716"
				placeholderImage="2023-03-08-fish-webinar-replay.jpg"
				publicView
			/>
			<BodyLanding pageName="download" publicView />
		</React.Fragment>
	)
}

export default DoximityDocNewsFish

export const Head = () => (
	<Seo
		title="Webinar Replay: Updates on Influenza and COVID-19: Trends and Treatments for the Urgent Care Setting"
		description="Updates on Influenza and COVID-19: Trends and Treatments for the Urgent Care Setting"
		image="/meta/webinar-fish-2023-03-08.jpg"
	/>
)
